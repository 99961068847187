import { useContext } from 'react';

import { UserContext } from '../store/UserContext';
import { features as localFeatures } from '../utils/СonfigСlient';
import { BackendFeatures, LocalFeatures } from '../typings/config';

export const useFeature = (feature: LocalFeatures | BackendFeatures): boolean => {
    const { features } = useContext(UserContext);
    const localFeature = localFeatures[feature as LocalFeatures];
    const userFeature = features.some((item: string) => item === feature);

    return Boolean(localFeature || userFeature);
};
