import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { BodyS, Button } from '@salutejs/plasma-b2c';

import { Icon } from '../Icon/Icon';

import { SberIdButtonWrap } from './SberIdButtonWrap';

const StyledButton = styled(Button)`
    border-radius: 12px;
    display: flex;
    flex-flow: row;
    justify-content: center;
`;

export const SberIdButtonText = styled(BodyS)`
    margin-left: 8px;
    margin-top: 4px;
`;

interface SberIdButtonProps {
    redirectUrl: string;
}

export const SberIdButtonM: React.FC<SberIdButtonProps> = ({ redirectUrl, ...props }) => {
    const { t } = useTranslation('sberId');

    return (
        <SberIdButtonWrap redirectUrl={redirectUrl}>
            <StyledButton pin="square-square" size="m" {...props} type="button" data-e2e="sberId">
                <Icon icon="sberLogo" width={24} height={24} color="#4D9D46" />
                <SberIdButtonText bold>{t('Сбер ID')}</SberIdButtonText>
            </StyledButton>
        </SberIdButtonWrap>
    );
};
