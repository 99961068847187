export enum EnvironmentsEnum {
    Dev = 'development',
    Stage = 'staging',
    Prod = 'production',
}

export interface Routes {
    login: string;
    main: string;
    createVideo: string;
    createSpeaker: string;
    selectSceneSpeaker: string;
    assemblyTable: string;
    setSceneName: string;
    editSpeaker: string;
    editSpeakerMenu: string;
    gameDevLanding: string;
    gameDevGeneration: string;
    gameDevPlugins: string;
    gameDevRigTutorial: string;
    gameDevBlendshapes: string;
    gameDevTutorial: string;
    scenePDFUpload: string;
    landing: string;
    activateAccount: string;
    resetPasswordRequest: string;
    resetPassword: string;
    landingRegistration: string;
    checkEmail: string;
    devDoc: string;
    devDocExample: string;
    speakerFaceChange: string;
    create3dSpeaker: string;
    edit3dSpeaker: string;
    purchases: string;
}

export interface Config {
    basename: string;
    devHost: string;
    prHost: string;
    stageHost: string;
    prodHost: string;
    apiPath: string;
    poolingTimeout: number;
    tokenRefreshSafeTime: number;
}

export enum LocalFeatures {
    FacebookSharing = 'facebook_sharing',
}

export enum BackendFeatures {
    VerticalVideo = 'vertical_video',
    Deepfake = 'deepfake',
    Exhibition = 'exhibition',
    CalcSectionTransitionTime = 'calcSectionTransitionTime',
    MobileSsml = 'mobile_ssml',
    NoBetaLogo = 'no_beta_logo',
    PersonalDataCheckbox = 'signup_checkbox',
    Moving = 'moving',
    FullHeightContent = 'scale_content',
}
