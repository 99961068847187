import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useToast } from 'hooks/useToast';

import { createSberIdAuthState, createSberIdAuthState2 } from '../../api/sberIdAuth'

interface SberIdButtonWrapProps {
    redirectUrl: string;
    children: ReactElement;
}

export const SberIdButtonWrap: React.FC<SberIdButtonWrapProps> = ({ redirectUrl, children }) => {
    const { showToast } = useToast();
    const { t } = useTranslation('sberId');
    const [sending, setSending] = useState<boolean>(false);

    const onClick = useCallback(async () => {
        setSending(true);

        try {
            await createSberIdAuthState2(redirectUrl);
        } catch {
            showToast.error(t('Ошибка авторизации через Сбер ID'));
        } finally {
            setSending(false);
        }
    }, [showToast, redirectUrl, t]);

    return React.cloneElement(children, { onClick, disabled: sending });
};
