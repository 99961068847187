import React, { ChangeEventHandler, FC, useState } from 'react';
import styled from 'styled-components';
import { tertiary } from '@salutejs/plasma-tokens-b2c';

import { AutofillInput } from '../AuthForm/AutofillInput';
import { Icon } from '../Icon/Icon';

type PasswordInputProps = {
    placeholder: string;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    isFailed: boolean;
    name?: string;
    onBlur?: React.InputHTMLAttributes<HTMLInputElement>['onBlur'];
    helperText?: string;
};

const StyledSwitchButton = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
    display: block;
    outline: none;
    padding: 0;
`;

const StyledIcon = styled(Icon)`
    display: block;
`;

export const PasswordInput: FC<PasswordInputProps> = ({
    placeholder,
    value,
    onChange,
    isFailed,
    name,
    onBlur,
    helperText,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <AutofillInput
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            status={isFailed ? 'error' : undefined}
            onBlur={onBlur}
            helperText={helperText}
            contentRight={
                <StyledSwitchButton
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    data-e2e="authPassSwitchButton"
                >
                    <StyledIcon
                        icon={showPassword ? 'eyeCrossed' : 'eye'}
                        width={24}
                        height={24}
                        color={isFailed ? '#DC283A' : tertiary}
                    />
                </StyledSwitchButton>
            }
            {...rest}
        />
    );
};
