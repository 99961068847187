import { TOptions } from 'i18next';
import { Resources, i18n } from 'next-i18next';

export function getI18nTranslation<R extends keyof Resources[N], N extends keyof Resources>(
    resource: N,
    text: R,
    options?: TOptions<{ count?: number }>,
    options1?: undefined,
): string | undefined;

export function getI18nTranslation<RR = never, R extends RR = RR, N extends keyof Resources = keyof Resources>(
    resource: N,
    text: R,
    defaultValue: string,
    options?: TOptions<{ count?: number }> | undefined,
): string;

export function getI18nTranslation<R extends keyof Resources[N], N extends keyof Resources = keyof Resources>(
    resource: N,
    text: R,
    defaultValue: string,
    options?: TOptions<{ count?: number }> | undefined,
): string;

export function getI18nTranslation(resource: any, text: any, options: any, options1?: any) {
    return i18n?.t(`${resource}:${text}`, options, options1);
}
