import React from 'react';
import styled from 'styled-components';
import { TextField } from '@salutejs/plasma-b2c';
import { white } from '@salutejs/plasma-tokens-b2c';

export const AutofillInput = styled(TextField)`
    & input {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-text-fill-color: ${white};
            -webkit-box-shadow: 0 0 0 1000px transparent inset;
            transition: background-color 5000s ease-in-out 0s;

            & + label {
                transform: scale(0.715);
                top: 0.375rem;
            }
        }
    }
`;
