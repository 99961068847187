import styled, { css } from 'styled-components';
import { withCdnUrl } from 'utils/static';
import { mobileMediaQueries } from 'utils/mobileMediaQueries';
import { spacing } from '@salutejs/plasma-core';
import React, { FC } from 'react';
import { routesConfig } from 'configs/routes';
import { mediaQuery } from '@salutejs/plasma-b2c';
import { containerWidth } from 'const/global';

import { Link } from '../Link/Link';

export const LogotypeRoot = styled(Link)`
    position: fixed;
    top: 0;
    left: 64px;
    mix-blend-mode: difference;
    cursor: pointer;
    display: block;
    text-decoration: none;
    width: 148px;
    height: 88px;
    background: no-repeat url(${withCdnUrl('images/logo-no-beta.svg')}) center/contain;

    ${mediaQuery('S')(css`
        left: ${spacing[16]};
    `)}

    ${mediaQuery('M')(css`
        left: 56px;
    `)}

    ${mediaQuery('L')(css`
        left: 56px;
    `)}

    ${css`
        @media screen and (min-width: 2000px) {
            left: calc(50% - ${containerWidth / 2}px);
        }
    `}

    ${mobileMediaQueries(css`
        background: no-repeat url(${withCdnUrl('images/logo-no-beta.svg')}) center/contain;
        top: 34px;
        width: 118px;
        height: 16px;
    `)}
`;

export const Logotype: FC = () => {
    return <LogotypeRoot data-e2e="logotype" href={routesConfig.landing} />;
};
