import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { TextXS } from '@salutejs/plasma-b2c';
import { tertiary } from '@salutejs/plasma-tokens-b2c';
import { spacing } from '@salutejs/plasma-core';

const StyledContainer = styled.div`
    display: block;
    margin-bottom: ${spacing[24]};
    height: 1px;
    position: relative;

    &::before,
    &::after {
        content: ' ';
        position: absolute;
        top: 0;
        width: calc(50% - 85px);
        background-color: ${tertiary};
        height: 1px;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
`;

const StyledText = styled(TextXS)`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${tertiary};
`;

export const Separator: React.FC = () => {
    const { t } = useTranslation('authForm');

    return (
        <StyledContainer>
            <StyledText>{t('или через соцсети')}</StyledText>
        </StyledContainer>
    );
};
