import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@salutejs/plasma-b2c';

import { Icon, IconsSetKey } from '../../Icon/Icon';
import { SocialAuthProvider, SocialAuthProviderData } from '../../../typings/auth';
import { authStorage } from '../../../utils/lsStorages';
import { LocalStorageKeys } from '../../../const/localStorage';

const StyledButton = styled(Button)`
    border-radius: 12px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 48px;
`;

interface SocialAuthButtonProps {
    providerData: SocialAuthProviderData;
    icon: IconsSetKey;
    iconColor?: string;
}

function socialAuthUrlFormatter(providerData: SocialAuthProviderData): string {
    const socialAuthUrlPatterns: Record<SocialAuthProvider, string> = {
        [SocialAuthProvider.Vk]:
            'https://oauth.vk.com/authorize?client_id={client_id}&display=popup&redirect_uri={redirect_uri}&response_type={response_type}&v=5.131 ',
        [SocialAuthProvider.Facebook]:
            'https://www.facebook.com/v12.0/dialog/oauth?client_id={client_id}&display=popup&redirect_uri={redirect_uri}&response_type={response_type}',
        [SocialAuthProvider.Google]:
            'https://accounts.google.com/o/oauth2/v2/auth?client_id={client_id}&redirect_uri={redirect_uri}&response_type={response_type}&scope=email',
        [SocialAuthProvider.Apple]: '',
    };

    return socialAuthUrlPatterns[providerData.provider]
        .replace('{client_id}', providerData.client_id)
        .replace('{redirect_uri}', providerData.redirect_uri)
        .replace('{response_type}', providerData.response_type);
}

export const SocialAuthButton: React.FC<SocialAuthButtonProps> = ({ providerData, icon, iconColor, ...props }) => {
    const onClick = useCallback(() => {
        authStorage.set(LocalStorageKeys.SocialAuthState, { provider: providerData.provider });
        window.location.href = socialAuthUrlFormatter(providerData);
    }, [providerData]);

    return (
        <StyledButton pin="square-square" size="m" onClick={onClick} {...props} type="button">
            <Icon icon={icon} width={24} height={24} color={iconColor} />
        </StyledButton>
    );
};
