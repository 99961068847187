import { EnvironmentsEnum, LocalFeatures } from '../typings/config';

export const features: { [key in EnvironmentsEnum]: { [feature in LocalFeatures]: boolean } } = {
    [EnvironmentsEnum.Prod]: {
        // флаг используется в e2e
        [LocalFeatures.FacebookSharing]: false,
    },
    [EnvironmentsEnum.Stage]: {
        // флаг используется в e2e
        [LocalFeatures.FacebookSharing]: false,
    },
    [EnvironmentsEnum.Dev]: {
        // флаг используется в e2e
        [LocalFeatures.FacebookSharing]: false,
    },
};
