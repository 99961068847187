import React, { useCallback, FormEvent, useState, useContext } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { spacing } from '@salutejs/plasma-core';
import { TextXS } from '@salutejs/plasma-b2c';
import { critical } from '@salutejs/plasma-tokens-b2c';
import { UserProfile } from '@sberdevices/vc-contracts';

import { UserContext } from '../../store/UserContext';
import { routesConfig } from '../../configs/routes';
import { signIn } from '../../api/auth';
import { fetchAnalytics } from '../../utils/fetchAnalytics';
import { getCurrentLocale } from '../../utils/locale';
import { PasswordInput } from '../PasswordInput/PasswordInput';

import { AutofillInput } from './AutofillInput';
import { AuthButton } from './AuthButton';

const StyledError = styled(TextXS)`
    color: ${critical};
    margin-bottom: ${spacing[16]};
    hyphens: none;
`;

const StyledInput = styled(AutofillInput)`
    margin-bottom: ${spacing[20]};
`;

const StyledPasswordInput = styled(PasswordInput)`
    margin-bottom: ${spacing[20]};
`;

const StyledPasswordWrap = styled.div`
    position: relative;
`;

interface LoginFormProps {
    onSubmit?(): void;
    onError?(): void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, onError }) => {
    const { t } = useTranslation('login');
    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const [isFailed, setIsFailed] = useState(false);
    const userContext = useContext(UserContext);
    const router = useRouter();

    const submitHandler = useCallback(
        (event: FormEvent) => {
            event.preventDefault();
            signIn(login, pass)
                .then((user) => {
                    const locale = getCurrentLocale(router, user as UserProfile);
                    userContext.updateFeatures();
                    userContext.setUser(user as UserProfile);
                    fetchAnalytics().then((analyticsInstance) =>
                        analyticsInstance.submit({
                            eventAction: 'User:Login',
                            eventCategory: 'account',
                            value: 'email',
                        }),
                    );
                    onSubmit?.();
                    router.push(routesConfig.main, routesConfig.main, { locale });
                })
                .catch(() => {
                    setIsFailed(true);
                    onError?.();
                });

            return false;
        },
        [login, pass, router, userContext, onSubmit, onError],
    );

    const onLoginChange = useCallback((event) => {
        setLogin(event.target.value);
        setIsFailed(false);
    }, []);

    const onPassChange = useCallback((event) => {
        setPass(event.target.value);
        setIsFailed(false);
    }, []);

    return (
        <form onSubmit={submitHandler}>
            {isFailed && (
                <StyledError bold data-e2e="authErrorText">
                    {t('authError')}
                </StyledError>
            )}

            <StyledInput
                placeholder={t('Ваша электронная почта')}
                value={login}
                name="login"
                onChange={onLoginChange}
                status={isFailed ? 'error' : undefined}
                data-e2e="authInputLogin"
                data-e2e-error={isFailed}
            />

            <StyledPasswordWrap>
                <StyledPasswordInput
                    placeholder={t('Пароль')}
                    value={pass}
                    name="password"
                    onChange={onPassChange}
                    isFailed={isFailed}
                    data-e2e="authInputPass"
                    data-e2e-error={isFailed}
                />
            </StyledPasswordWrap>

            <AuthButton failed={isFailed}>{t('Войти')}</AuthButton>
        </form>
    );
};
