import React from 'react';
import styled from 'styled-components';
import { Button } from '@salutejs/plasma-b2c';
import { spacing } from '@salutejs/plasma-core';

const StyledButton = styled(Button)`
    margin-top: ${spacing[4]};
    margin-bottom: ${spacing[32]};
    border-radius: ${spacing[12]};
`;

interface AuthButtonProps {
    failed: boolean;
}

export const AuthButton: React.FC<AuthButtonProps> = ({ failed, children, ...props }) => {
    return (
        <StyledButton
            pin="square-square"
            view="primary"
            stretch
            type="submit"
            disabled={failed}
            data-e2e="authButtonSubmit"
            {...props}
        >
            {children}
        </StyledButton>
    );
};
