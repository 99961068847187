import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'next-i18next';
import { BodyXS, Checkbox, spacing } from '@salutejs/plasma-b2c';
import { buttonAccent, secondary } from '@salutejs/plasma-tokens-b2c';
import { recaptchaExecute, recaptchaInit, recaptchaReset } from 'utils/recaptcha';
import { useRouter } from 'next/router';
import { SignUpApi } from '@sberdevices/vc-contracts';
import { useFeature } from 'hooks/useFeature';
import { BackendFeatures } from 'typings/config';

import { validateEmail } from '../../utils/validators';
import { Link } from '../Link/Link';
import { RecaptchaV2 } from '../RecaptchaV2/RecaptchaV2';
import { getCurrentLocale } from '../../utils/locale';
import { routesConfig } from '../../configs/routes';
import { SignUpCreateData } from '../../typings/auth';
import { basePath, configuration } from '../../configs/base';
import { baseAxiosInstance } from '../../utils/interceptor';
import { fetchGa } from '../../utils/fetchAnalytics';

import { AutofillInput } from './AutofillInput';
import { AuthButton } from './AuthButton';

const axiosInstance = new SignUpApi(configuration, basePath, baseAxiosInstance);

const StyledInput = styled(AutofillInput)`
    margin-bottom: ${spacing[16]};
`;

const LandingLink = styled(Link)`
    text-decoration: none;
    color: ${buttonAccent};

    &:hover {
        filter: brightness(120%);
    }
`;

const StyledCheckbox = styled(Checkbox)`
    width: 100%;
    margin-left: 0;
    margin-bottom: ${spacing[16]};
    overflow: hidden;

    & > input + div {
        margin-top: 0;
    }

    & > div:last-child {
        width: calc(100% - 32px);
        margin-top: 3px;
        margin-left: ${spacing[10]};
    }
`;

const StyledCheckboxText = styled(BodyXS)`
    color: ${secondary};
    white-space: break-spaces;
    hyphens: none;
`;

const RECAPTCHA_ID = 'g-recaptcha';

const stopPropagation: React.MouseEventHandler<HTMLAnchorElement> = (e) => e.stopPropagation();

interface RegFormProps {
    onSubmit?(): void;
    onError?(): void;
}

export const RegForm: React.FC<RegFormProps> = ({ onSubmit, onError }) => {
    const { t } = useTranslation('signup');
    const router = useRouter();
    const showPersonalDataCheckbox = useFeature(BackendFeatures.PersonalDataCheckbox);
    const [touched, touch] = useReducer(() => true, false);
    const [login, setLogin] = useState('');
    const [widgetId, setWidgetId] = useState<number>();
    const requestData = useRef<Partial<SignUpCreateData>>({});
    const [lettersCheckbox, toggleLettersCheckbox] = useReducer(
        (currentValue) => !currentValue,
        !showPersonalDataCheckbox,
    );
    const emailValidationMessage = useMemo(() => validateEmail(login) as string, [login]);
    const failed = Boolean(touched && emailValidationMessage);
    const [sending, setSending] = useState(false);

    const onLoginChange = useCallback((event) => setLogin(event.target.value), []);

    const submitHandler: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();

            if (emailValidationMessage) {
                return;
            }

            if (widgetId === undefined) {
                onError?.();

                return;
            }

            requestData.current = {
                email: login,
                subscription_on: !showPersonalDataCheckbox ? false : lettersCheckbox,
                redirect_url: routesConfig.main,
                term_approve: lettersCheckbox,
            };
            recaptchaExecute(widgetId);
        },
        [emailValidationMessage, lettersCheckbox, login, onError, showPersonalDataCheckbox, widgetId],
    );

    const signUpRequest = useCallback(async () => {
        try {
            setSending(true);

            await axiosInstance.signUpCreate(requestData.current as SignUpCreateData);

            fetchGa().then((analytics) => {
                analytics.push({
                    eventAction: 'lead',
                    properties: {
                        reg_type: 'email',
                    },
                });
            });

            onSubmit?.();

            router.push(routesConfig.checkEmail);
        } catch (_) {
            if (widgetId !== undefined) {
                recaptchaReset(widgetId);
            }

            onError?.();
        } finally {
            setSending(false);
        }
    }, [onSubmit, router, widgetId, onError]);

    const handleRecaptchaVerify = useCallback(
        (token: string) => {
            requestData.current = {
                ...requestData.current,
                language: getCurrentLocale(router),
                captcha: token,
            };
            signUpRequest();
        },
        [router, signUpRequest],
    );

    useEffect(() => {
        if (widgetId === undefined) {
            recaptchaInit(RECAPTCHA_ID, handleRecaptchaVerify).then(setWidgetId);
        }
    }, [handleRecaptchaVerify, widgetId]);

    return (
        <form onSubmit={submitHandler}>
            <StyledInput
                placeholder={t('Электронная почта')}
                value={login}
                name="login"
                onChange={onLoginChange}
                onBlur={touch}
                status={failed ? 'error' : undefined}
                helperText={failed ? emailValidationMessage : ''}
                data-e2e="signUpEmailInput"
                data-e2e-error={failed}
            />

            {showPersonalDataCheckbox && (
                <div data-e2e="lettersCheckbox">
                    <StyledCheckbox
                        checked={lettersCheckbox}
                        onChange={toggleLettersCheckbox}
                        label={
                            (
                                <StyledCheckboxText bold>
                                    <Trans
                                        t={t}
                                        i18nKey="Даю Согласие на обработку персональных данных и получение рассылки"
                                    >
                                        Даю{' '}
                                        <LandingLink
                                            onClick={stopPropagation}
                                            href="https://visper.tech/constant/consent.pdf"
                                            target="_blank"
                                            rel="nofollow"
                                        >
                                            Согласие
                                        </LandingLink>{' '}
                                        на обработку персональных данных и получение рассылки
                                    </Trans>
                                </StyledCheckboxText>
                            ) as JSX.Element
                        }
                    />
                </div>
            )}

            <AuthButton failed={sending || Boolean(emailValidationMessage) || !lettersCheckbox} data-e2e="register">
                {t('Зарегистрироваться')}
            </AuthButton>

            <RecaptchaV2 recaptchaId={RECAPTCHA_ID} />
        </form>
    );
};
