import { getI18nTranslation } from './getI18nTranslation';

export const MIN_PASSWORD_LENGTH = 8;

export const validateEmail = (email: string): string | undefined => {
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // RFC 5322

    if (!email.length) {
        return getI18nTranslation('validators', 'Не указана почта');
    }

    if (!emailRegExp.test(email)) {
        return getI18nTranslation('validators', 'Почта указана некорректно');
    }
};

export const validatePassword = (password: string): string | undefined => {
    if (password.length < MIN_PASSWORD_LENGTH) {
        return getI18nTranslation('validators', 'Пароль должен содержать минимум {{count}} символов', {
            count: MIN_PASSWORD_LENGTH,
        });
    }
};

export const validateConfirmPassword = (password: string, confirmPassword: string): string | undefined => {
    if (password !== confirmPassword) {
        return getI18nTranslation('validators', 'Пароли не совпадают');
    }
};

export const validateNewPassword = (password: string): string | undefined => {
    const defaultValidationMessage = validatePassword(password);

    if (defaultValidationMessage) {
        return defaultValidationMessage;
    }

    const passwordRegexp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$/;

    if (!passwordRegexp.test(password)) {
        return getI18nTranslation(
            'validators',
            'Новый пароль должен содержать от 8 символов, содержать одну заглавную и строчную буквы, а также одну цифру',
        );
    }
};
