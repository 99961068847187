import React, { useCallback, useReducer } from 'react';
import { secondary, white, accent, tertiary } from '@salutejs/plasma-tokens-b2c';
import styled from 'styled-components';
import { Trans, useTranslation } from 'next-i18next';
import { H2, TextXS } from '@salutejs/plasma-b2c';
import { textXSBold } from '@salutejs/plasma-typo';
import { useRouter } from 'next/router';
import { spacing } from '@salutejs/plasma-core';

import { routesConfig } from '../../configs/routes';
import { Link } from '../Link/Link';
import { LEGAL_TERMS_EN, LEGAL_TERMS_RU } from '../../const/global';
import { useLocale } from '../../hooks/useLocale';

import { SocialAuthBlock } from './SocialAuthBlock/SocialAuthBlock';
import { LoginForm } from './LoginForm';
import { Separator } from './Separator';
import { RegForm } from './RegForm';

const StyledContainer = styled.div`
    width: 100%;
    max-width: 392px;
    margin: 0 auto;
`;

const StyledTitleBlock = styled.div`
    display: flex;
    flex-flow: row;
    margin-bottom: ${spacing[24]};
`;

interface StyledTitleProps {
    $active?: boolean;
}

const StyledTitle = styled(H2)<StyledTitleProps>`
    margin-right: 40px;
    color: ${({ $active }) => ($active ? white : secondary)};
    cursor: ${({ $active }) => ($active ? 'default' : 'pointer')};

    &:last-child {
        margin-right: 0;
    }
`;

const StyledDesc = styled(TextXS)`
    display: block;
    text-align: center;
    color: ${tertiary};
    margin-top: ${spacing[24]};
    hyphens: none;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${accent};
    ${textXSBold}

    &:hover {
        filter: brightness(120%);
    }
`;

export enum AuthFormMode {
    Login,
    Reg,
}

interface AuthFormProps {
    defaultMode: AuthFormMode;
    forwardTabLink?: string;
    onSubmit?(): void;
    onError?(): void;
}

export const AuthForm: React.FC<AuthFormProps> = ({ defaultMode, forwardTabLink, onSubmit, onError, ...props }) => {
    const { t } = useTranslation('authForm');
    const { code } = useLocale();
    const router = useRouter();
    const [mode, toggleMode] = useReducer(
        (value) => (value === AuthFormMode.Login ? AuthFormMode.Reg : AuthFormMode.Login),
        defaultMode,
    );

    const tabClickHandle = useCallback(() => {
        if (forwardTabLink) {
            router.push(forwardTabLink);
        } else {
            toggleMode();
        }
    }, [forwardTabLink, router]);

    return (
        <StyledContainer {...props}>
            <StyledTitleBlock>
                {mode === AuthFormMode.Login ? (
                    <>
                        <StyledTitle $active>{t('Войти')}</StyledTitle>
                        <StyledTitle onClick={tabClickHandle} data-e2e="signUpLink">
                            {t('Регистрация')}
                        </StyledTitle>
                    </>
                ) : (
                    <>
                        <StyledTitle $active data-e2e="signUpLink">
                            {t('Регистрация')}
                        </StyledTitle>
                        <StyledTitle onClick={tabClickHandle}>{t('Войти')}</StyledTitle>
                    </>
                )}
            </StyledTitleBlock>

            {mode === AuthFormMode.Login ? (
                <LoginForm onSubmit={onSubmit} onError={onError} />
            ) : (
                <RegForm onSubmit={onSubmit} onError={onError} />
            )}

            <Separator />

            <SocialAuthBlock />

            <StyledDesc>
                {mode === AuthFormMode.Login ? (
                    <StyledLink href={routesConfig.resetPasswordRequest}>{t('Я забыл пароль')}</StyledLink>
                ) : (
                    <Trans
                        t={t}
                        i18nKey="Нажимая «Зарегистрироваться», вы соглашаетесь с условиями <1>Пользовательского соглашения</1>"
                    >
                        Нажимая «Зарегистрироваться», вы соглашаетесь с условиями{' '}
                        <StyledLink
                            href={code === 'ru' ? LEGAL_TERMS_RU : LEGAL_TERMS_EN}
                            as="a"
                            target="_blank"
                            rel="nofollow"
                        >
                            Пользовательского соглашения
                        </StyledLink>
                    </Trans>
                )}
            </StyledDesc>
        </StyledContainer>
    );
};
