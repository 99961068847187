import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { white } from '@salutejs/plasma-tokens-b2c';
import { mediaQuery } from '@salutejs/plasma-b2c';
import { spacing } from '@salutejs/plasma-core';

import { routesConfig } from '../../../configs/routes';
import { SberIdButtonM, SberIdButtonText } from '../../SberIdButton/SberIdButtonM';
import { SocialAuthProvider, SocialAuthProviderData } from '../../../typings/auth';
import { IconsSetKey } from '../../Icon/Icon';
import { getSocialAuthProviders } from '../../../api/socialAuth';

import { SocialAuthButton } from './SocialAuthButton';

const StyledSocialButtonsContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
`;

const StyledSocialButton = styled(SocialAuthButton)`
    margin-right: ${spacing[8]};

    ${mediaQuery('L')(css`
        margin-right: ${spacing[12]};
    `)}

    ${mediaQuery('M')(css`
        margin-right: ${spacing[12]};
    `)}

    ${mediaQuery('S')(css`
        margin-right: ${spacing[12]};
    `)}
`;

const socialButtonSize = 48 + 8;

const StyledSberIdButton = styled(SberIdButtonM)`
    width: calc(100% - ${socialButtonSize}px * 4);
    max-width: 168px;
    margin-right: ${spacing[8]};

    ${mediaQuery('L')(css`
        width: 48px;
    `)}

    ${mediaQuery('M')(css`
        width: 48px;
    `)}

    ${mediaQuery('S')(css`
        width: 48px;
    `)}

    ${SberIdButtonText} {
        ${mediaQuery('L')(css`
            display: none;
        `)}

        ${mediaQuery('M')(css`
            display: none;
        `)}

        ${mediaQuery('S')(css`
            display: none;
        `)}
    }
`;

interface SocProviderDataMapItem {
    icon: IconsSetKey;
    iconColor?: string;
}

const socialProvidersDataMap: Record<SocialAuthProvider, SocProviderDataMapItem> = {
    [SocialAuthProvider.Apple]: {
        icon: 'apple',
        iconColor: white,
    },
    [SocialAuthProvider.Google]: {
        icon: 'gmail',
    },
    [SocialAuthProvider.Vk]: {
        icon: 'vk',
        iconColor: '#5181B8',
    },
    [SocialAuthProvider.Facebook]: {
        icon: 'facebook',
        iconColor: '#157DC3',
    },
};

export const SocialAuthBlock: React.FC = (props) => {
    const [providers, setProviders] = useState<SocialAuthProviderData[]>([]);

    useEffect(() => {
        getSocialAuthProviders().then(setProviders);
    }, []);

    return (
        <StyledSocialButtonsContainer {...props}>
            <StyledSberIdButton redirectUrl={routesConfig.main} data-e2e="sberId" />

            {providers.map((providerData) =>
                socialProvidersDataMap[providerData.provider] ? (
                    <StyledSocialButton
                        key={providerData.provider}
                        providerData={providerData}
                        icon={socialProvidersDataMap[providerData.provider].icon}
                        iconColor={socialProvidersDataMap[providerData.provider].iconColor}
                        data-e2e={providerData.provider}
                    />
                ) : null,
            )}
        </StyledSocialButtonsContainer>
    );
};
