import { features as configsFeatures } from '../configs/features';
import { WORKFLOW_NAME } from '../const/environment';

export function getFeatures() {
    const env = process.env.WORKFLOW_NAME as WORKFLOW_NAME;

    return (
        {
            [WORKFLOW_NAME.Pr]: configsFeatures.staging,
            [WORKFLOW_NAME.Dev]: configsFeatures.staging,
            [WORKFLOW_NAME.Release]: configsFeatures.production,
            [WORKFLOW_NAME.Local]: configsFeatures.development,
        }[env] || configsFeatures.development
    );
}

export const features = getFeatures();
